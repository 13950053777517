<template>
  <div class="timing" :class="{ mobile: props.isMobile }">
    <template v-if="isLive">
      <div class="live-score" data-t="live-score">
        <span>{{ scores.home }}</span>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="6" y="3" width="4" height="4" rx="2" fill="white" />
          <rect x="6" y="10" width="4" height="4" rx="2" fill="white" />
        </svg>
        <span>{{ scores.away }}</span>
      </div>
      <div class="live-clock" data-t="live-clock">
        <span data-t="status">{{ matchTiming }}</span>
        <span v-if="isShownClock" data-t="match-time">, {{ clock }}</span>
      </div>
    </template>
    <template v-else>
      <div class="info-time" data-t="time">
        {{ startTime.hour }}
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="6" y="3" width="4" height="4" rx="2" fill="white" />
          <rect x="6" y="10" width="4" height="4" rx="2" fill="white" />
        </svg>
        {{ startTime.minute }}
      </div>
      <span class="info-date" data-t="date">
        {{ startDate }}
      </span>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { TSportEvent } from 'markets-store'
import { useEventInfo } from '../../composables/useEventInfo'

const props = defineProps<{
  event: TSportEvent
  isMobile?: boolean
}>()

const { parseDate } = useDate()
const { event } = toRefs(props)

const { startDate } = useStartDate(event)

const startTime = computed(() => ({
  hour: parseDate(props.event.scheduled).format('HH'),
  minute: parseDate(props.event.scheduled).format('mm'),
}))

const { matchTiming, isShownClock, clock } = useLiveClock(event, {
  onlyMinutes: true,
  matchTimingFormat: 'short',
})

const { isLive, scores } = useEventInfo(event)
</script>

<style scoped>
.info-time {
  display: flex;
  align-items: center;

  padding-top: var(--spacing-050);

  color: var(--text-primary);
  /* stylelint-disable */
  font-family: 'SF Pro Display';
  font-size: 26px;
  font-style: italic;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 1.04px;
  /* stylelint-enable */
}

.info-date {
  font: var(--desktop-text-xs-medium);
  color: var(--text-primary);
  white-space: nowrap;
}

.live-score {
  display: flex;
  align-items: center;

  padding-top: var(--spacing-050);

  /* stylelint-disable */
  font-size: 28px;
  font-weight: 700;
  font-style: italic;
  line-height: 24px;
  letter-spacing: 1.04px;
  /* stylelint-enable */
}

.live-clock {
  display: flex;
  gap: var(--spacing-025);
  justify-content: center;
  font: var(--desktop-text-xs-medium);
}

.timing {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-025);
  align-items: center;
  justify-content: center;

  &.mobile {
    gap: var(--spacing-050);

    .info-time {
      padding: var(--spacing-050) 0;
    }

    .info-date,
    .live-clock {
      font: var(--mobile-caption-1-regular);
    }

    .live-score {
      padding: var(--spacing-050) 0;
      font-size: 26px; /* stylelint-disable-line */
    }
  }
}
</style>
